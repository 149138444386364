import React, { useState, useMemo } from "react"

import * as S from "./styled"
import { createSubmit } from "src/common/hubspot.js"
import SuccessModal from "src/common/SuccessModal"
import Button from "src/newUikit/Button"
import { useBreakpoint } from "src/common/hooks"
import Input, { ErrorContainer, Error } from "src/uikit/Input/Input"

const fields = ["email"]

const SubscribeForm = ({ content }) => {
  const [status, setStatus] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const isShowLabel = useBreakpoint(599)

  const [form, setForm] = useState({
    email: "",
    errors: {},
  })

  const submit = useMemo(
    () =>
      createSubmit({
        portalId: content.portalId,
        formId: content.formId,
      }),
    [content]
  )

  const handleChangeInput = e => {
    const { id, value } = e.target
    setForm({
      ...form,
      [id]: value,
    })
    setIsDisabled(!value)
  }

  const handleSubmit = async () => {
    const errors = {}
    const { email } = form

    if (email.trim() === "") {
      errors.email = content.errorEmpty
    }

    if (errors.email !== undefined) {
      setForm({
        ...form,
        errors,
      })
      return
    }

    setIsLoading(true)

    try {
      const result = await submit(fields, form)

      if (result.status === 200) {
        setStatus(1)
        setForm(prevValue => ({ ...prevValue, errors: {} }))
      }
    } catch (err) {
      const hubSpotErrors = {}

      err.response?.data?.errors?.forEach(({ errorType }) => {
        if (errorType === "INVALID_EMAIL") {
          hubSpotErrors.email = content.errorEmail
        }
      })

      setForm(prevValue => ({ ...prevValue, errors: hubSpotErrors }))
    }

    setIsLoading(false)
  }

  const handleClose = () => setStatus(0)

  if (status === 1) {
    return (
      <SuccessModal
        body={
          <S.Content>
            <h3>{content.successTitle}</h3>
            <p>
              {content.successText}{" "}
              <a href={`mailto:${form.email}`}>{form.email}</a>
            </p>
          </S.Content>
        }
        closeButton={content.successCloseButton}
        isOpen
        toggle={handleClose}
      />
    )
  }

  return (
    <S.Container>
      <div className="inner">
        <div className="input-box">
          {isShowLabel && <label>{content.placeholder}</label>}
          <Input
            type="email"
            id="email"
            name="email"
            onChange={handleChangeInput}
            value={form.email}
            isValid={!form.errors.email}
            placeholder={!isShowLabel && content.placeholder}
          />
          <ErrorContainer>
            {!!form.errors.email && <Error>{form.errors.email}</Error>}
          </ErrorContainer>
        </div>
        <Button disabled={isLoading || isDisabled} onClick={handleSubmit}>
          {content.subscribe}
        </Button>
      </div>
    </S.Container>
  )
}

export default SubscribeForm
