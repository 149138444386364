import styled, { createGlobalStyle } from "styled-components"

import { Section, Title } from "../../uikit"
import {
  DESKTOP_HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
  EXTRA_MOBILE_HEADER_HEIGHT,
} from "src/developers/constants"

export const Container = styled(Section)`
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 40px;
  padding-bottom: 0 !important;
  margin-top: ${64 + DESKTOP_HEADER_HEIGHT}px;
  margin-bottom: 104px;

  text-align: left;

  ${Title} {
    font-weight: 800;
    font-size: 48px;
    line-height: 64px;
    text-align: center;
    color: #001553;
    margin-bottom: 8px;
  }

  .posts-list {
    list-style: none;
    margin: 0;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
    margin-top: 24px;
    margin-bottom: 40px;
  }

  @media (min-width: 1600px) {
    .posts-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      margin-top: 24px;
    }
  }

  @media (min-width: 1920px) {
    .posts-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      width: 1612px;
    }
  }

  @media (max-width: 1200px) {
    .posts-list {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media (max-width: 1199px) {
    margin-top: ${40 + MOBILE_HEADER_HEIGHT}px;
    margin-bottom: 56px;

    .posts-list {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 24px;
    }
  }

  @media (max-width: 1150px) {
    .posts-list {
      grid-template-columns: 1fr 1fr;
      margin-bottom: 44px;
    }
  }

  @media (max-width: 1023px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (max-width: 600px) {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 48px;

    .posts-list {
      grid-template-columns: 1fr;
      grid-gap: 16px;
      margin-bottom: 32px;
    }
  }

  @media (max-width: 599px) {
    margin-top: ${24 + EXTRA_MOBILE_HEADER_HEIGHT}px;
    padding-left: 12px;
    padding-right: 12px;

    ${Title} {
      text-align: left;
      width: 100%;
      font-size: 32px;
      line-height: 40px;
      font-weight: bold;
    }
  }
`

export const LoadMoreButton = styled.button`
  border: none;
  background: none;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  line-height: 16px;
  outline: none;

  text-align: center;
  text-transform: uppercase;

  cursor: pointer;

  height: 56px;

  color: #5855f4;

  margin-bottom: 40px;

  @media (max-width: 1150px) {
    margin-bottom: 44px;
  }

  @media (max-width: 600px) {
    margin-bottom: 32px;
  }
`

export const GlobalStyles = createGlobalStyle`
  /* https://css-tricks.com/almanac/properties/o/overflow-anchor/ */
  body {
    overflow-anchor: none;
  }
`
