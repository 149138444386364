export const normalizeCategories = categories => {
  const names = {}

  categories.forEach((name, id) => {
    const arr = id.split(".")
    const isSubcategories = arr.length > 1
    const keyName = id.split(".")[0]

    if (isSubcategories) {
      const title = name.split(".")[0]
      const itemName = name.split(".")[1]
      const item = { categoryId: id, name: itemName }

      names[keyName] = names[keyName]
        ? { ...names[keyName], items: [...names[keyName].items, item] }
        : { categoryId: keyName, name: title, items: [item] }
      return
    }

    names[keyName] = { categoryId: keyName, name, items: [] }
  })

  return Array.from(Object.keys(names)).map(name => {
    if (names[name].items.length > 0) {
      return names[name]
    }

    return { categoryId: names[name].categoryId, name: names[name].name }
  })
}
