import React from "react"

import { ArrowDown } from "../../../../media"
import { Category } from "../Categories/styled"

import * as S from "./styled"

const Subcategories = ({ label, items, selectedCategory, setCategory }) => {
  return (
    <S.Container>
      <S.Label title={label}>
        {label} <ArrowDown />
      </S.Label>
      <ul className="list">
        {items.map(({ categoryId, name }) => (
          <li className="list-item" key={categoryId}>
            <Category
              type="button"
              title={name}
              active={selectedCategory === categoryId}
              onClick={setCategory(categoryId)}
            >
              {name}
            </Category>
          </li>
        ))}
      </ul>
    </S.Container>
  )
}

export default Subcategories
