import styled from "styled-components"

import { FONT } from "src/developers/constants"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .inner {
    display: flex;
  }

  input {
    min-width: 384px;
    height: 56px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    ::-webkit-input-placeholder {
      color: rgba(0, 21, 83, 0.5);
    }

    ::-ms-input-placeholder {
      color: rgba(0, 21, 83, 0.5);
    }

    ::placeholder {
      color: rgba(0, 21, 83, 0.5);
    }

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus {
      color: rgba(0, 21, 83, 0.5);
      -webkit-text-fill-color: rgba(0, 21, 83, 0.5);
      -webkit-box-shadow: none;
      font-family: ${FONT.PRIMARY};
    }
  }

  button {
    width: 147px;
    height: 56px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  label {
    font-family: ${FONT.PRIMARY};
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    color: rgba(0, 21, 83, 0.5);
  }

  @media only screen and (max-width: 599px) {
    .inner {
      flex-direction: column;
      align-items: center;
      padding: 0 12px;
    }

    button {
      width: 288px;
      height: 48px;
      border-radius: 12px;
    }

    .input-box,
    input,
    .inner {
      width: 100%;
    }

    input {
      min-width: 100%;
      border-radius: 6px;
      height: 40px;
    }
  }

  @media (max-width: 413px) {
    .inner {
      padding: 0 4px;
    }
  }
`

export const Content = styled.div`
  h3 {
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    margin-bottom: 16px;
    letter-spacing: 0.4px;
  }

  p {
    font-size: 15px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.4px;
    margin-bottom: 12px;
  }

  a {
    color: #5855f4;
    text-decoration: none;
    font-size: 15px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.4px;
  }

  a:hover {
    color: #5855f4;
  }

  margin: 0;
`
