import styled from "styled-components"

import { FONT } from "src/developers/constants"

export const Container = styled.div``

export const Label = styled.button`
  background-color: #fff;
  border: none;
  width: 100%;
  height: 56px;
  font-family: ${FONT.PRIMARY};
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #001553;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 20px;
  outline: none;
  cursor: pointer;

  @media (max-width: 599px) {
    height: 28px;
    font-size: 15px;
    line-height: 28px;
    padding-right: 8px;
  }
`

export const List = styled.ul`
  list-style: none;
  margin: 0;
  max-height: ${props => (props.open ? "100%" : "0")};
  overflow: hidden;
`

export const ListItem = styled.li``

export const Button = styled.button`
  background-color: #fff;
  border: none;
  width: 100%;
  height: 56px;
  font-family: ${FONT.PRIMARY};
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #001553;
  display: flex;
  align-items: center;
  padding-left: 12px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;

  :hover {
    background-color: #f5f6f7;
  }

  :active {
    background: #dee0e6;
  }

  @media (max-width: 599px) {
    height: 40px;
    font-size: 15px;
    line-height: 28px;
    padding-left: 8px;
  }
`
