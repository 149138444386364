import React, { useState, useMemo, useEffect } from "react"
import qs from "qs"

import { Title } from "src/uikit"

import * as S from "./styled"
import { CATEGORIES } from "./constants"
import { normalizeCategories } from "./utils"
import Post from "./components/Post"
import Categories from "./components/Categories"
import SubscribeForm from "./components/SubscribeForm"
import useContent from 'src/developers/useContent'
import "animate.css/animate.min.css"

const normalizePostCards = posts => {
  // eslint-disable-next-line react/display-name
  const PostItem = post => () => (
    <li className="posts-list-item animate__fadeIn animate__animated">
      <Post {...post} />
    </li>
  )

  if (posts.length === 0) {
    return []
  }

  if (posts.length > 0 && posts.length < 5) {
    return posts.map(post => PostItem(post))
  }

  let normalizedPosts = []
  const chunks = []

  for (let i = 0; i < posts.length; i = i + 5) {
    chunks.push(posts.slice(i, i + 5))
  }

  const newChunks = chunks.map(chunk => {
    const newChunk = []

    chunk.forEach(post => {
      newChunk.push(PostItem(post))
    })

    return newChunk
  })

  newChunks.forEach(chunk => {
    normalizedPosts = [...normalizedPosts, ...chunk]
  })

  return normalizedPosts
}

const PostsSection = ({ posts, location, content, categories }) => {
  const locales = useContent()

  const queryCategory = qs.parse(location.search, { ignoreQueryPrefix: true })
    .category
  const isInUsedCategories = useMemo(() => !!categories.has(queryCategory), [
    categories,
    queryCategory,
  ])
  const [category, setCategory] = useState(
    isInUsedCategories ? queryCategory : CATEGORIES.ALL
  )
  const [defaultPostsToShow, setDefaultsPostsToShow] = useState(9)
  const [postsToShow, setPostsToShow] = useState(defaultPostsToShow)

  useEffect(() => {
    const handler = () => {
      let newDefaultPostsToShow

      if (window.innerWidth >= 1920) {
        newDefaultPostsToShow = 12
      } else if (window.innerWidth > 1150 && window.innerWidth < 1920) {
        newDefaultPostsToShow = 9
      } else if (window.innerWidth <= 1150 && window.innerWidth >= 601) {
        newDefaultPostsToShow = 8
      }

      setDefaultsPostsToShow(prevValue => newDefaultPostsToShow || prevValue)
      setPostsToShow(newDefaultPostsToShow || defaultPostsToShow)
    }

    handler()

    window.addEventListener("resize", handler)

    return () => window.removeEventListener("resize", handler)
  }, [defaultPostsToShow, setDefaultsPostsToShow, setPostsToShow])

  useEffect(() => {
    if (history.pushState) {
      const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?category=${category}`
      window.history.pushState({ path: newurl }, "", newurl)
    }

    setPostsToShow(defaultPostsToShow)
  }, [category, setPostsToShow])

  const filteredPosts = useMemo(
    () =>
      normalizePostCards(
        posts.filter(
          post =>
            (post.category && post.category.categoryId === category) ||
            category === CATEGORIES.ALL
        )
      ),
    [category, posts]
  )

  const normalizedCategories = useMemo(() => normalizeCategories(categories), [
    categories,
  ])

  const handleLoadMore = () => {
    setPostsToShow(prevValue => prevValue + defaultPostsToShow)
  }

  return (
    <S.Container>
      <S.GlobalStyles />
      <Title as="h1" id="sectionHeading">{locales.blog.title}</Title>
      <Categories
        categories={normalizedCategories}
        category={category}
        setCategory={setCategory}
      />
      <ul className="posts-list">
        {filteredPosts.map(
          (Item, i) => i + 1 <= postsToShow && <Item key={i} />
        )}
      </ul>
      {filteredPosts.length > postsToShow && (
        <S.LoadMoreButton type="button" onClick={handleLoadMore}>
          {locales.blog.loadMoreLabel}
        </S.LoadMoreButton>
      )}
      <SubscribeForm content={content} />
    </S.Container>
  )
}

export default PostsSection
