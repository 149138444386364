import React, { useState } from "react"
import styled, { ThemeProvider } from "styled-components"
import { IntlContextProvider } from "gatsby-plugin-intl"

import { usePosts } from "src/common/hooks"
import PostsSection from "../components/PostsSection"
import AcceptCookieForm from "../components/AcceptCookieForm"
import ModalsContainer from "../components/ModalsContainer"
import TopBanner from "../common/TopBanner"
import { GlobalStyle } from "../templates/BlogPost/styled"
import SEO from "../seo"

import themes from "../developers/theme.json"
import useContent from "../developers/useContent"
import ContactSalesModal from "../developers/ContactSales"
import Header from "../developers/Header"
import Footer from "../developers/Footer"

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const BlogPage = ({ location }) => {
  const content = useContent()
  const pageTitle = "Blog";
  const { posts, categories } = usePosts()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleToggleModal = () => setIsModalOpen(prevValue => !prevValue)

  return (
    <ThemeProvider theme={themes.light}>
      <IntlContextProvider>
        <GlobalStyle />
        <Wrap id="layout">
          <AcceptCookieForm />
          <SEO title={pageTitle} location={location} />
          <TopBanner />
          <Header content={content} openModal={handleToggleModal} />
          <PostsSection
            content={content.blog}
            posts={posts}
            location={location}
            categories={categories}
          />
          <Footer content={content.footer} />
          <ContactSalesModal
            content={content.salesModal}
            isOpen={isModalOpen}
            onClose={handleToggleModal}
          />
          <ModalsContainer location={location} />
        </Wrap>
      </IntlContextProvider>
    </ThemeProvider>
  )
}

export default BlogPage
