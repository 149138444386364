import styled from "styled-components"

import { media } from "src/uikit"
import { FONT } from "src/developers/constants"
import { Container as SubcategoriesContainer } from "../SubDropdown/styled"

export const Container = styled.div`
  width: 688px;
  height: 40px;
  position: relative;

  @media ${media.mobile} {
    width: 100%;
    height: 40px;
  }
`

export const Label = styled.button`
  background-color: #f7f7f9;
  border: none;
  border-radius: 6px;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${FONT.PRIMARY};
  font-weight: 600;
  font-size: 15px;
  line-height: 28px;
  color: #001553;
  padding: 6px 6px 8px 8px;
`

export const Hidden = styled.div`
  opacity: ${props => (props.isOpen ? 1 : 0)};
  visibility: ${props => (props.isOpen ? "visible" : "hidden")};
  transition: 0.3s opacity, 0.3s visibility;

  display: flex;

  width: 100%;
  position: absolute;
  top: 46px;
  left: 0;
  z-index: 1000;
  box-shadow: 0px 2px 4px rgba(29, 44, 127, 0.6);
  border-radius: 6px;

  ${SubcategoriesContainer} {
    button {
      height: 24px;
      padding-left: 16px;
      margin-bottom: 10px;
    }
  }

  @media (max-width: 599px) {
    padding: 8px;

    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 21, 83, 0.5);
    box-shadow: none;
    border-radius: 0;
    overflow-y: scroll;

    ${SubcategoriesContainer} {
      button {
        padding-left: 8px;
      }
    }
  }
`

export const List = styled.ul`
  list-style: none;
  margin: auto;
  width: 100%;
  background-color: #ffffff;
  border-radius: 6px;

  @media (max-width: 599px) {
    overflow-y: auto;
    box-shadow: 0px 2px 4px rgba(29, 44, 127, 0.6);
    border-radius: 6px;
    border: 1px solid #ffff;
    height: auto;
    max-height: 100%;
  }
`

export const ListItem = styled.li`
  :first-child {
    margin-top: 16px;
  }

  :last-child {
    margin-bottom: 16px;
  }

  @media (max-width: 599px) {
    &:first-child {
      margin-top: 8px;
      border-radius: 6px 6px 0 0;
      border-top: 1px solid #fff;
    }

    &:last-child {
      margin-bottom: 8px;
      border-bottom: 1px solid #fff;
      border-radius: 0 0 6px 6px;

      button {
        margin-bottom: 0;
      }
    }
  }
`

export const Button = styled.button`
  background-color: #fff;
  border: none;
  width: 100%;
  height: 24px;
  margin-bottom: 10px;
  font-family: ${FONT.PRIMARY};
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #001553;
  display: flex;
  align-items: center;
  padding-left: 16px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;

  :hover {
    color: #5855f4;
  }

  @media (max-width: 599px) {
    height: 28px;
    font-size: 15px;
    line-height: 28px;
    padding-left: 8px;
    margin-bottom: 8px;

    :hover {
      background-color: #f5f6f7;
    }

    :active {
      background: #dee0e6;
    }
  }
`
