import React, { useMemo, useState, useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"

import { CATEGORIES } from "../../constants"
import {
  DESKTOP_HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
  EXTRA_MOBILE_HEADER_HEIGHT,
} from "src/developers/constants"

import Dropdown from "../Dropdown"
import Subcategories from "../Subcategories"
import * as S from "./styled"

const Categories = ({ categories, category, setCategory }) => {
  const { formatMessage } = useIntl()
  const [isStick, setIsStick] = useState(false)
  const [menuHeight, setMenuHeight] = useState(null)
  const [isShowMobile, setIsShowMobile] = useState(false)

  useEffect(() => {
    const handler = () => {
      const footerEl = document.querySelector("footer") || {}
      const headingEl = document.getElementById("sectionHeading")
      const headingElOffsetTop =
        headingEl.getBoundingClientRect().top + window.pageYOffset
      let headerHeight = DESKTOP_HEADER_HEIGHT

      if (window.innerWidth < 1200 && window.innerWidth > 599) {
        headerHeight = MOBILE_HEADER_HEIGHT
      } else if (window.innerWidth < 600) {
        headerHeight = EXTRA_MOBILE_HEADER_HEIGHT
      }

      if (!isShowMobile) {
        const el = document.querySelector(".desktop-container")
        if (!el) {
          return
        }
        setMenuHeight(el.clientHeight)
        let updatedIsStick = isStick

        if (
          window.pageYOffset >= el.offsetTop &&
          window.pageYOffset > headingElOffsetTop
        ) {
          updatedIsStick = true
        } else {
          updatedIsStick = false
        }

        setIsStick(updatedIsStick)

        if (
          window.pageYOffset + headerHeight + el.clientHeight >=
          footerEl.offsetTop
        ) {
          el.style.top = `-${el.clientHeight}px`
        } else {
          el.style.top = updatedIsStick ? `${headerHeight}px` : 0
        }
      } else {
        const el = document.querySelector(".mobile-container")

        if (!el) {
          return
        }

        setMenuHeight(el.clientHeight)

        let updatedIsStick = isStick

        if (
          window.pageYOffset >= el.offsetTop &&
          window.pageYOffset > headingElOffsetTop
        ) {
          updatedIsStick = true
        } else {
          updatedIsStick = false
        }

        setIsStick(updatedIsStick)

        if (
          window.pageYOffset + headerHeight + el.clientHeight >=
          footerEl.offsetTop
        ) {
          el.style.top = `-${el.clientHeight}px`
        } else {
          el.style.top = updatedIsStick ? `${headerHeight}px` : 0
        }
      }
    }

    handler()

    window.addEventListener("scroll", handler)

    return () => {
      window.removeEventListener("scroll", handler)
    }
  }, [setIsStick, setMenuHeight])

  useEffect(() => {
    const handler = () => {
      const listEl = document.querySelector(".desktop-container")

      if (!listEl) {
        return
      }

      const childrenWidth = Array.from(listEl.children).reduce(
        (acc, cur) => acc + cur.clientWidth,
        0
      )

      if (listEl.clientWidth - childrenWidth < 300) {
        setIsShowMobile(true)
      } else {
        setIsShowMobile(false)
      }
    }

    handler()

    window.addEventListener("resize", handler)

    return () => window.removeEventListener("resize", handler)
  }, [setIsShowMobile])

  const normalizedCategories = useMemo(
    () => [
      {
        categoryId: CATEGORIES.ALL,
        name: formatMessage({ id: "categoryAll" }),
      },
      ...categories,
    ],
    [categories]
  )

  const handleClick = key => () => setCategory(key)

  return (
    <>
      <S.Placeholder menuHeight={menuHeight} hide={isStick ? 0 : 1} />
      <S.Container
        className="desktop-container"
        isShowMobile={isShowMobile}
        stick={isStick ? 1 : 0}
      >
        {normalizedCategories.map(({ categoryId, name, items }) =>
          items ? (
            <Subcategories
              label={name}
              items={items}
              selectedCategory={category}
              setCategory={handleClick}
            />
          ) : (
            <S.Category
              type="button"
              active={category === categoryId}
              key={categoryId}
              title={name}
              onClick={handleClick(categoryId)}
            >
              {name}
            </S.Category>
          )
        )}
      </S.Container>
      <S.MobileContainer
        className="mobile-container"
        isShowMobile={isShowMobile}
        stick={isStick ? 1 : 0}
      >
        <Dropdown
          items={normalizedCategories}
          value={category}
          setValue={setCategory}
        />
      </S.MobileContainer>
    </>
  )
}

export default Categories
