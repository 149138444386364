import styled from "styled-components"

import { media } from "../../../../uikit"
import {
  DESKTOP_HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
  EXTRA_MOBILE_HEADER_HEIGHT,
  FONT,
} from "src/developers/constants"

export const Placeholder = styled.div`
  height: ${props => (props.hide ? 0 : props.menuHeight)}px;
`

export const Container = styled.div`
  width: ${props => (props.isShowMobile ? "calc(100% - 80px)" : "100%")};
  padding-top: 16px;
  padding-bottom: 16px;

  visibility: ${props => (props.isShowMobile ? "hidden" : "visible")};
  position: ${props =>
    props.isShowMobile ? "absolute" : props.stick ? "fixed" : "relative"};

  background-color: #fff;
  border-bottom: ${props =>
    props.stick ? "1px solid rgba(222, 224, 230, 0.7)" : "none"};

  top: ${props => (props.stick ? DESKTOP_HEADER_HEIGHT : 0)}px;
  left: 0;
  z-index: 2;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 1199px) {
    top: ${props => (props.stick ? MOBILE_HEADER_HEIGHT : 0)}px;
  }

  @media (max-width: 1024px) {
    padding-bottom: 24px;
  }

  @media (max-width: 768px) {
    padding-bottom: 0;
  }

  @media (max-width: 599px) {
    top: ${props => (props.stick ? EXTRA_MOBILE_HEADER_HEIGHT : 0)}px;
    white-space: nowrap;
  }
`

export const Category = styled.button`
  font-family: ${FONT.PRIMARY};
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;

  white-space: nowrap;

  color: ${props => (props.active ? "#5855F4" : "#001553")};
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  transition: 0.3s color;

  margin: 0 16px;
  padding: 0;

  :hover {
    color: #5855f4;
  }

  ::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  @media ${media.mobile} {
    margin-top: 8px;
    margin-bottom: 8px;
  }
`

export const MobileContainer = styled.div`
  display: ${props => (props.isShowMobile ? "flex" : "none")};

  align-items: center;
  justify-content: center;

  width: 100%;
  padding: ${props => (props.stick ? "8px 16px 8px 16px" : "8px 0 0 0")};

  background-color: #fff;
  border-bottom: ${props =>
    props.stick ? "1px solid rgba(222, 224, 230, 0.7)" : "none"};

  position: ${props => (props.stick ? "fixed" : "relative")};
  top: ${props => (props.stick ? 56 : 0)}px;
  left: 0;
  z-index: 3;
  transition: ${props => (props.stick ? "0.3s top" : "none")};

  @media (max-width: 1199px) {
    top: ${props => (props.stick ? MOBILE_HEADER_HEIGHT : 0)}px;
  }

  @media (max-width: 599px) {
    top: ${props => (props.stick ? EXTRA_MOBILE_HEADER_HEIGHT : 0)}px;
  }
`
