import styled from "styled-components"

import { media } from "../../../../uikit"
import { Category } from "../Categories/styled"
import { FONT } from "src/developers/constants"

export const Container = styled.div`
  margin: 0 16px;
  position: relative;

  :hover {
    .list {
      visibility: visible;
      opacity: 1;
    }
  }

  .list {
    list-style: none;
    margin: 0;
    padding: 24px;
    background-color: #fff;
    box-shadow: 0px 6px 14px rgba(15, 21, 78, 0.24);
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 50%;
    width: 248px;
    transform: translate(-50%, 40px);

    visibility: hidden;
    opacity: 0;
    transition: 0.5s visibility, 0.5s opacity;

    ::before {
      content: "";
      width: 100%;
      position: absolute;
      height: 16px;
      top: -16px;
      left: 0;
    }
  }

  .list-item {
    margin: 9px 0;

    :first-child {
      margin-top: 0;
    }

    :last-child {
      margin-bottom: 0;
    }
  }

  ${Category} {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.4px;

    margin: 0;
  }

  @media ${media.tablet} {
    .list {
      width: auto;
    }
  }

  @media ${media.mobile} {
    flex-basis: 120px;

    margin: 8px 16px;
  }
`

export const Label = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: ${FONT.PRIMARY};
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #001553;
  white-space: nowrap;
  min-width: 120px;

  svg {
    margin-left: 2px;
  }
`
