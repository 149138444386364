import React, { useState, useEffect, useMemo, useRef } from "react"
import { enableBodyScroll, disableBodyScroll } from "body-scroll-lock"

import { ArrowUp, ArrowDown } from "src/media"

import SubDropdown from "../SubDropdown"
import * as S from "./styled"

const Dropdown = ({ items, value, setValue }) => {
  const [isOpen, setIsOpen] = useState(false)
  const menuEl = useRef(null)
  const menuContainerEl = useRef(null)

  useEffect(() => {
    const handler = e => {
      if (
        menuContainerEl.current &&
        !menuContainerEl.current.contains(e.target)
      )
        setIsOpen(false)
    }

    document.addEventListener("click", handler)

    return () => {
      document.removeEventListener("click", handler)
    }
  }, [setIsOpen])

  useEffect(() => {
    const headerEl = document.getElementById("header")

    if (isOpen && window.innerWidth < 600) {
      headerEl.style.zIndex = "1"
      disableBodyScroll(menuEl.current || document.body)
    } else {
      headerEl.style.zIndex = "999"
      enableBodyScroll(menuEl.current || document.body)
    }

    return () => {
      enableBodyScroll(menuEl.current || document.body)
    }
  }, [isOpen])

  const handleClickOutside = e => {
    if (e.target === e.currentTarget) {
      setIsOpen(false)
    }
  }

  const activeItem = useMemo(() => {
    let result = null

    items.forEach(item => {
      if (item.items) {
        item.items.forEach(child => {
          if (child.categoryId === value) {
            result = child
          }
        })
      }

      if (item.categoryId === value) {
        result = item
      }
    })

    return result
  }, [value, items])

  const filteredItems = useMemo(
    () => items.filter(item => item.categoryId !== value),
    [value, items]
  )

  const toggle = () => setIsOpen(prevValue => !prevValue)

  const handleItemClick = key => () => {
    setIsOpen(false)
    setValue(key)
  }

  const handleSubDropdownChange = () => {
    setIsOpen(false)
  }

  return (
    <S.Container ref={menuContainerEl}>
      <S.Label type="button" onClick={toggle}>
        {activeItem?.name} {isOpen ? <ArrowUp /> : <ArrowDown />}
      </S.Label>
      <S.Hidden ref={menuEl} isOpen={isOpen} onClick={handleClickOutside}>
        <S.List>
          {filteredItems.map(item => (
            <S.ListItem key={`m-${item.categoryId}`}>
              {item.items ? (
                <SubDropdown
                  title={item.name}
                  items={item.items}
                  value={value}
                  setValue={setValue}
                  onChange={handleSubDropdownChange}
                />
              ) : (
                <S.Button
                  type="button"
                  onClick={handleItemClick(item.categoryId)}
                >
                  {item.name}
                </S.Button>
              )}
            </S.ListItem>
          ))}
        </S.List>
      </S.Hidden>
    </S.Container>
  )
}

export default Dropdown
