import React, { useState } from "react"

import { ArrowDown, ArrowUp } from "src/media"

import * as S from "./styled"

const SubDropdown = ({ title, items, value, setValue, onChange }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = () => setIsOpen(prevValue => !prevValue)

  const handleItemClick = key => () => {
    setIsOpen(false)
    setValue(key)
    onChange()
  }

  return (
    <S.Container>
      <S.Label type="button" active={isOpen} onClick={handleClick}>
        {title} {isOpen ? <ArrowUp /> : <ArrowDown />}
      </S.Label>
      <S.List open={isOpen}>
        {items.map(item =>
          item.key !== value ? (
            <S.ListItem key={`m-${item.categoryId}`}>
              <S.Button
                type="button"
                onClick={handleItemClick(item.categoryId)}
              >
                {item.name}
              </S.Button>
            </S.ListItem>
          ) : (
            ""
          )
        )}
      </S.List>
    </S.Container>
  )
}

export default SubDropdown
